import axios from 'axios';
import cfg from 'lib@/cfg';
import user from 'lib@/user';
import ShowMsgError from 'lib@/errors/show_msg_error';
import NeedLoginError from 'lib@/errors/need_login_error';

/**
 * 父窗口对象
 */
let parentWindow = window.parent;

/**
 * 发起 http 请求的基础类
 */
class HttpClient {
    constructor() {
        const tokenHeaderKey = cfg.token_header_key;
        const projectVar     = cfg.project_var;
        const buildNo        = cfg.build_no;
        const protectedMode  = cfg.protected_mode;

        /**
         * 是否 隐藏loading
         */
        this._hideLoading  = 0;

        /**
         * 并发请求时 loading 的引用数
         */
        this._countLoading = 0;

        /**
         * 当前 loading 对象的标识
         */
        this._indexLoading = null;

        this.httpClient = axios.create({
            baseURL: cfg.api_url,
        });

        this.httpClient.interceptors.request.use(
            async config => {

                config.headers[tokenHeaderKey]     = user.userToken;
                config.headers['Project-Var']      = projectVar;
                config.headers['Build-No']         = buildNo;
                config.headers['Protected-Mode']   = protectedMode;
                config.headers['X-Requested-With'] = 'XMLHttpRequest';

                this._showLoading();

                return config;
            },
            error => Promise.reject(error)
        );

        this.httpClient.interceptors.response.use(
            async res => {
                this._closeLoading();

                if (res.data.code === 401) {
                    throw new NeedLoginError('');
                }
                else if(res.data.code != 1){
                    throw new ShowMsgError(res.data.message);
                }

                const tmp              = tokenHeaderKey.toLowerCase();
                let   skipRefreshToken = res.config.skipRefreshToken || false;

                // 因为后端有 token 自动更新的策略，所以在这用
                if(tmp in res.headers && !skipRefreshToken){
                    user.tokenFrom = 'auto_refresh';
                    user.userToken = res.headers[tmp];
                }

                res.payload = res.data.data;

                // 触发后端接口返回事件
                if(parentWindow.$){
                    parentWindow.$("body").trigger('backendResponse', res.config);
                }

                return res;
            },
            err => {
                this._closeLoading();

                // TODO 提示请求出错
                return Promise.reject(err);
            }
        );
    }

    // GET
    get(url, params, options = null) {
        let config = {
            params: this.prepareParams(params),
            ...options,
        }
        return this.httpClient.get(
            url,
            config
        );
    }

    // DELETE
    delete(url, params = null, options = null) {
        let config = {
            params: this.prepareParams(params),
            ...options,
        }
        return this.httpClient.delete(
            url,
            config
        );
    }

    // POST
    post(url, data, options = null) {
        return this.httpClient.post(url, this.prepareParams(data), options);
    }

    // PUT
    put(url, data, options) {
        return this.httpClient.put(url, this.prepareParams(data), options);
    }

    // 预处理请求参数
    prepareParams(params) {
        const newParams = {};

        if((typeof params) != 'object'){
            return params;
        }

        if (params) {
            // eslint-disable-next-line
            for (const key in params) {
                if (!params.hasOwnProperty(key)) {
                    continue;
                }

                // GET 请求的一些优化，空的默认的都不传
                if (params[key] === null || params[key] === undefined) {
                    continue;
                }

                if (key === 'page' && params[key] === 1) {
                    continue;
                }

                newParams[key] = params[key];
            }
        }

        return newParams;
    }

    disableLoading() {
        this._hideLoading = 1;
        return this;
    }

    enableLoading() {
        this._hideLoading = 0;
        return this;
    }

    _showLoading() {
        if(this._hideLoading == 0){
            if(this._countLoading == 0){
                this._indexLoading = layui.layer.load(2);
            }
            ++this._countLoading;
        }
    }

    _closeLoading() {
        if(this._hideLoading == 0){
            --this._countLoading;
            if(this._countLoading < 1){
                layui.layer.close(this._indexLoading);
            }
        }
    }
}

export default new HttpClient();
